import {
  get,
  noop,
  pick,
  uniq,
  without,
  isEmpty,
  flatten,
  difference,
  find,
} from 'lodash-es'
import DataProvider from '../data/DataProvider'
import {
  convertFromCustomFormat,
  convertToCustomFormat,
} from '@wix/cloud-elementory-protocol'
import { PRIMARY } from '../data/sequenceType'
import DATASET_TYPES from '@wix/wix-data-client-common/src/datasetTypes'
import completeControllerConfigs from '../dataset-controller/completeControllerConfigs'
import { parseUrlPattern } from '@wix/dbsm-common/src/dynamic-pages/urlUtils'
import {
  isEnvEditor,
  isModePreview,
  isModeLivePreview,
} from '../helpers/viewMode'
import { traceCreators } from '../logger'
import getReferencedCollectionsNames from '../schemas/getReferencedCollectionsNames'
import createDatabindingVerboseReporter from '../verbose/databindingVerboseReporter'
import Deferred from '../helpers/Deferred'
import { TraceType } from '../logger/traceType'
import { createRecordStoreService } from '../record-store'
import createControllerFactory from '../dataset-controller/controllerFactory'

export default class DataBinding {
  #dataProvider
  #dataCache
  #features

  //TODO: invert
  #wixSdk
  #appLogger
  #errorReporter
  #verboseReporter
  #routerPayload
  #schemaAPI
  #automationsClient
  #shouldVerbose
  #recordStoreCache
  #wixDataProxy

  constructor({
    dataFetcher,
    dataCache,
    features,

    appLogger,
    errorReporter,
    wixSdk,
    routerReturnedData,
    shouldVerbose,
    originalVerboseReporter,
    automationsClientCreator,
    elementorySupport,
    schemaAPI,
    wixDataProxy,
  }) {
    this.#dataProvider = new DataProvider({
      dataFetcher,
      appLogger,
      errorReporter,
    })
    this.#dataCache = dataCache
    this.#features = features

    const viewMode = getViewMode(wixSdk)

    this.#wixSdk = wixSdk
    this.#appLogger = appLogger
    this.#errorReporter = errorReporter
    this.#shouldVerbose = shouldVerbose
    this.#verboseReporter =
      shouldVerbose && isModePreview(viewMode) ? originalVerboseReporter : noop
    this.#schemaAPI = schemaAPI
    this.#routerPayload = routerReturnedData || {}
    this.#automationsClient = automationsClientCreator(elementorySupport)
    this.#recordStoreCache = {}
    this.#wixDataProxy = wixDataProxy
  }

  initializeDatasets({
    //TODO: temp interface
    rawControllerConfigs,
  }) {
    const modeIsSSR = isSSRMode(this.#wixSdk)
    const modeIsCSR = !modeIsSSR
    const controllerConfigs = completeControllerConfigs(
      rawControllerConfigs,
      this.#routerPayload,
    )
    const viewMode = getViewMode(this.#wixSdk)
    const { routerData, dynamicPagesData } = extractRouterPayload(
      this.#routerPayload,
      convertFromCustomFormat,
      controllerConfigs,
    )
    const warmupDataIsEnabled = this.#features.warmupData
    const reportFormEventToAutomation =
      this.#automationsClient.reportFormEventToAutomationCreator({
        isPreview: isEnvEditor(viewMode),
      })
    const instansiateDatabindingVerboseReporter =
      createDatabindingVerboseReporter(
        this.#verboseReporter,
        this.#shouldVerbose,
      )
    const fetchingAllDatasetsData = []
    const renderingControllers = []
    const {
      resolve: renderDeferredControllers,
      promise: renderingRegularControllers,
    } = new Deferred()

    startLoadingSchemas({
      controllerConfigs,
      modeIsCSR,
      modeIsSSR,
      warmupDataIsEnabled,
      appLogger: this.#appLogger,
      routerPayload: this.#routerPayload,
      schemaAPI: this.#schemaAPI,
      dataCache: this.#dataCache,
    })

    const cachedStore =
      modeIsCSR &&
      warmupDataIsEnabled &&
      convertFromCache(this.#dataCache.get('dataStore'))

    if (cachedStore) {
      this.#dataProvider.setStore(cachedStore)
    }

    this.#dataProvider.setStore(routerData) //TODO: consider moving router data to cache
    this.#dataProvider.createBulkRequest(
      getBulkRequestConfigs(controllerConfigs),
    )

    const controllers = controllerConfigs.map(
      ({
        type,
        config,
        connections,
        $w,
        compId: datasetId,
        livePreviewOptions: {
          shouldFetchData: dataIsInvalidated,
          compsIdsToReset: updatedCompIds = [],
        } = {},
        platformAPIs,
        wixCodeApi: wixSdk,
      }) => {
        const { datasetIsRouter, datasetIsDeferred } =
          config.datasetStaticConfig
        this.#appLogger.trace(
          TraceType.Breadcrumb({
            level: 'info',
            category: 'createControllers',
            message: 'warmup data contents',
            data: {
              datasetId,
              datasetType: type,
              env: get(wixSdk, ['window', 'rendering', 'env']),
              warmupData: Boolean(cachedStore),
            },
          }),
        )

        const recordStoreService = createRecordStoreService({
          primaryDatasetId: datasetId,
          recordStoreCache: this.#recordStoreCache,
          refreshStoreCache: dataIsInvalidated,
          warmupStore: undefined,
          dataProvider: this.#dataProvider,
          controllerConfig: config,
          logger: this.#appLogger,
        })

        const {
          promise: fetchingDatasetData,
          resolve: markDatasetDataFetched,
        } = new Deferred()
        if (!datasetIsRouter && !datasetIsDeferred) {
          // But router will be in dataStore anyway. Filter out?
          fetchingAllDatasetsData.push(fetchingDatasetData)
        }

        const {
          promise: renderingController,
          resolve: markControllerAsRendered,
        } = new Deferred()
        renderingControllers.push(renderingController)

        const controllerFactory = createControllerFactory(this.#appLogger, {
          $w,
          controllerConfig: config,
          datasetType: type,
          connections,
          recordStoreService,
          wixDataProxy: this.#wixDataProxy,
          dataProvider: this.#dataProvider,
          firePlatformEvent: this.#appLogger.userCodeZone($w.fireEvent),
          wixSdk,
          errorReporter: this.#errorReporter,
          verboseReporter: this.#verboseReporter,
          instansiateDatabindingVerboseReporter,
          dynamicPagesData: datasetIsRouter ? dynamicPagesData : undefined,
          appLogger: this.#appLogger,
          datasetId,
          handshakes: [],
          schemaAPI: this.#schemaAPI,
          reportFormEventToAutomation,
          platformAPIs,
          updatedCompIds,
          markControllerAsRendered,
          markDatasetDataFetched,
          renderingRegularControllers,
          // isModeLivePreview is ture only if the LivePreview feature is enabled,
          // since in other case the Viewer won't be loaded at all
          modeIsLivePreview: isModeLivePreview(viewMode),
          modeIsSSR,
        })

        const datasetController = extractPlatformControllerAPI(
          controllerFactory.createPrimaryController(),
        )
        return Promise.resolve(datasetController)
      },
    )

    if (modeIsSSR && warmupDataIsEnabled && fetchingAllDatasetsData.length) {
      Promise.all(fetchingAllDatasetsData).then(() => {
        this.#dataCache.set(
          'dataStore',
          convertToCache(this.#dataProvider.getStore()),
        )
      })
    }
    Promise.all(renderingControllers).then(renderDeferredControllers)

    return controllers
  }
}

const isSSRMode = sdk => get(sdk, ['window', 'rendering', 'env']) === 'backend'
const getViewMode = sdk => get(sdk, ['window', 'viewMode'])

const extractRouterPayload = (payload, parser, controllerConfigs) => {
  if (!Object.keys(payload).length) return {}

  const routerDataset = find(controllerConfigs, {
    type: DATASET_TYPES.ROUTER_DATASET,
  })
  const datasetId = routerDataset && routerDataset.compId
  if (!datasetId) return {}
  const collectionName = get(routerDataset, 'config.dataset.collectionName')

  const {
    dynamicUrl,
    userDefinedFilter,
    items = [],
    totalCount,
    config,
    schemas,
  } = payload
  const parsedItems = parser(items)
  const record = parsedItems[0]
  const datasetSort = get(config, 'dataset.sort', []) || []
  const patternFields =
    dynamicUrl && record ? parseUrlPattern(dynamicUrl).fields : []
  const datasetSortFields = getDatasetSortFields(datasetSort)
  const unsortedPatternFields = difference(patternFields, datasetSortFields)
  const sort = getSortObject([
    ...datasetSort,
    ...getDefaultFieldsSort(unsortedPatternFields),
  ])
  const sortFields = [...datasetSortFields, ...unsortedPatternFields]
  const [dynamicUrlFieldKey] = Object.entries(
    schemas[collectionName].fields,
  ).find(
    ([, fieldDescriptor]) =>
      fieldDescriptor.calculator?.config.pattern === dynamicUrl,
  )

  const dynamicUrlPatternFieldsValues =
    extractDynamicUrlPatternFieldsValuesFromRecord(
      record,
      sortFields,
      patternFields,
    )

  return {
    routerData: {
      recordsInfoByDataset: {
        [datasetId]: {
          itemIds: parsedItems.map(({ _id }) => _id),
          totalCount,
        },
      },
      recordsByCollection: {
        [collectionName]: parsedItems.reduce(
          (acc, record) => ({
            ...acc,
            [record._id]: record,
          }),
          {},
        ),
      },
    },
    dynamicPagesData: {
      dynamicUrl,
      dynamicUrlFieldKey,
      userDefinedFilter,
      dynamicUrlPatternFieldsValues,
      sort,
      sortFields,
      patternFields,
    },
  }
}

const getBulkRequestConfigs = datasetConfigs =>
  datasetConfigs.reduce(
    (
      acc,
      {
        compId: datasetId,
        config: {
          datasetStaticConfig: { sequenceType },
        },
        livePreviewOptions: { shouldFetchData } = {},
      },
    ) =>
      sequenceType === PRIMARY
        ? [...acc, { id: datasetId, refresh: shouldFetchData }]
        : acc,
    [],
  )

const getDatasetSortFields = sort =>
  flatten(sort.map(sortItem => Object.keys(sortItem).map(key => key)))

const getSortObject = sortArray =>
  sortArray.reduce(
    (accumulator, currentValue) => Object.assign(accumulator, currentValue),
    {},
  )

const getDefaultFieldsSort = patternFields =>
  patternFields.map(field => ({ [field]: 'asc' }))

const extractDynamicUrlPatternFieldsValuesFromRecord = (
  record,
  sortFields,
  patternFields,
) => {
  const sortAndPatternFields = patternFields.concat(sortFields)
  return patternFields.length ? pick(record, sortAndPatternFields) : null
}

const startLoadingSchemas = ({
  controllerConfigs,
  modeIsCSR,
  modeIsSSR,
  warmupDataIsEnabled,
  routerPayload,
  schemaAPI,
  appLogger,
  dataCache,
}) => {
  const prefetchedSchemaData =
    warmupDataIsEnabled && modeIsCSR ? dataCache.get('schemas') : undefined

  if (prefetchedSchemaData) {
    return schemaAPI.loadPrefetched(prefetchedSchemaData)
  }

  const collectionIds = getMainCollectionIds(controllerConfigs)

  if (
    collectionIds.length === 1 &&
    hasAllSchemasReferencedByCollection(collectionIds[0], routerPayload.schemas)
  ) {
    return schemaAPI.loadPrefetched(routerPayload.schemas)
  }

  return appLogger
    .traceAsync(traceCreators.loadSchemas(), () =>
      schemaAPI.loadSchemas(collectionIds).then(schemas => {
        if (warmupDataIsEnabled && modeIsSSR) {
          dataCache.set('schemas', schemas)
        }
      }),
    )
    .catch(() => {})
}

const getMainCollectionIds = controllerConfigs => {
  const ids = controllerConfigs.map(controllerConfig =>
    get(
      controllerConfig,
      [
        'config',
        'dataset',
        'collectionName', // is actually collectionId :(
      ],
      null,
    ),
  )
  return without(uniq(ids), null)
}

const hasAllSchemasReferencedByCollection = (collectionId, schemas) => {
  if (isEmpty(schemas)) {
    return false
  }

  const mainSchema = schemas[collectionId]
  if (isEmpty(mainSchema)) {
    return false
  }

  const referencedCollectionIds = getReferencedCollectionsNames(mainSchema)
  return (
    isEmpty(referencedCollectionIds) ||
    referencedCollectionIds.every(cid => !isEmpty(schemas[cid]))
  )
}

const extractPlatformControllerAPI = ({ pageReady, exports, dispose }) => ({
  pageReady,
  exports,
  dispose,
})

const createConverter = convert => dataStore => {
  // TODO: change format to ISO string
  if (dataStore) {
    return {
      ...dataStore,
      recordsByCollection: Object.entries(dataStore.recordsByCollection).reduce(
        (acc, [collection, recordsById]) => {
          acc[collection] = convert(recordsById)
          return acc
        },
        {},
      ),
    }
  }
}
const convertToCache = createConverter(convertToCustomFormat)
const convertFromCache = createConverter(convertFromCustomFormat)
